import CircleDown from '../assets/circle-down.svg'

const Middle = () => {
    return <section className={'middle'}>
        <p className={'middle-text'}>LDA heroes, thank you for making The Club great</p>
        <span>
            <img src={CircleDown} alt=""/>
        </span>
    </section>
}

export default Middle