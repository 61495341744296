import HeroAnchorA from '../assets/hero_anchor_a.png'
import HeroAnchorB from '../assets/hero_anchor_b.png'
import HeroAnchorC from '../assets/hero_anchor_c.png'
import HeroAnchorD from '../assets/hero_anchor_d.png'
import HeroAnchorE from '../assets/hero_anchor_e.png'
import React, { useState } from 'react';

import MenuIcon from '../assets/menu.png'
import CloseIcon from '../assets/close.png'

const Hero = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    return <section id="hero" className={'hero'}>
        <iframe className={'hero-video'} src="https://www.youtube.com/embed/R28BV70vKYs" title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen></iframe>
        <div className={'hero-anchor-container'}>
            <img onClick={() => { window.location.hash = "#hero" }} className={'hero-anchor'} src={HeroAnchorA} alt="" />
            <img onClick={() => { window.location.hash = "#god" }} className={'hero-anchor'} src={HeroAnchorB} alt="" />
            <img onClick={() => { window.location.hash = "#ah" }} className={'hero-anchor'} src={HeroAnchorC} alt="" />
            <img onClick={() => { window.location.hash = "#ip" }} className={'hero-anchor'} src={HeroAnchorD} alt="" />
            <img onClick={() => { window.location.hash = "#qz" }} className={'hero-anchor'} src={HeroAnchorE} alt="" />
        </div>
        {
         !drawerOpen?   
            <img onClick={()=>{setDrawerOpen(true)}} className={'menu-btn btn'} src={MenuIcon} alt="" />:
            <img onClick={()=>{setDrawerOpen(false)}} className={'close-btn btn'} src={CloseIcon} alt="" />
        }
        {
            drawerOpen && (
                <div className={'drawer'}>
                    <img onClick={()=>{setDrawerOpen(false)}} className={'close-btn btn'} src={CloseIcon} alt="" />
                    <img onClick={() => { setDrawerOpen(false);window.location.hash = "#hero" }} className={'drawer-hero-anchor'} src={HeroAnchorA} alt="" />
                    <img onClick={() => { setDrawerOpen(false);window.location.hash = "#god" }} className={'drawer-hero-anchor'} src={HeroAnchorB} alt="" />
                    <img onClick={() => { setDrawerOpen(false);window.location.hash = "#ah" }} className={'drawer-hero-anchor'} src={HeroAnchorC} alt="" />
                    <img onClick={() => { setDrawerOpen(false);window.location.hash = "#ip" }} className={'drawer-hero-anchor'} src={HeroAnchorD} alt="" />
                    <img onClick={() => { setDrawerOpen(false);window.location.hash = "#qz" }} className={'drawer-hero-anchor'} src={HeroAnchorE} alt="" />
                </div>
            )
        }
    </section>
}

export default Hero;