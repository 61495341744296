import MainSvg from '../assets/speech-babel.svg'
import SecondSvg from '../assets/speech-babel-blue.svg'
import MainCross from "../assets/cross.svg";
import SecondCross from '../assets/cross-white.svg'

const SpeechBabelWrapper = ({variant = 'main', onClose, ...props}) => {
    const isMain = variant === 'main';
    return <div
        className={'speech-babel '+(isMain?'main':'')}
        style={{ 
            // backgroundImage: isMain ? `url(${MainSvg})` : `url(${SecondSvg})`, 
            color: isMain ? 'var(--color-main)' : '#fff',
        }}>
        <img onClick={() => onClose()} className={'speech-babel-close'} src={isMain ? MainCross :SecondCross } alt={'close'}/>
        {props.children}
    </div>

}

export default SpeechBabelWrapper;