import Member from "./Member";
import {useState} from "react";
import SpeechBabel from "./SpeechBabel";

const Teammates = () => {
    const members = require('../assets/data.json');
    // const topFive = ['Ray Chan', 'Bricky Lee', 'Ronald Poon', 'Sam Yau', 'Samuel Wong'];
    const memberNames = Array.from(new Set(members.map(member => member.Nominee))).filter(name => !!name);
    const [currentMember, setCurrentMember] = useState(undefined);
    const msgs = members.filter(member => member.Nominee === currentMember)
    console.log(msgs)
    return <section className={'teammates'}>
        <p className={'p'}>Click on your name to see thank you messages from your teammates.</p>
        <div className={'teammates-wrapper'}>
            <div id="ah" className={'teammates-members'}>
                {memberNames.map(name => <Member onSetMember={() => setCurrentMember(name)} key={name} name={name} />)}
            </div>
        </div>
        {currentMember && <SpeechBabel onClose={() => setCurrentMember(null)} variant={'secondary'} msgs={msgs} />}
    </section>
}

export default Teammates;