const Member = ({name, onSetMember, ...props}) => {
    const newProps = {}
    if(name==="Iris Lai"){
        newProps.id="ip"
    }
    if(name==="Queenie Yu"){
        newProps.id="qz"
    }

    return <div {...newProps} onClick={() => onSetMember()} className={'member'}>
        <span>{name}</span>
        {props.children}
    </div>
}

export default Member;