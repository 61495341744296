import Hero from './component/Hero';
import GodTeammates from "./component/GodTeammates";
import Middle from './component/Middle'
import Teammates from "./component/Teammates";
import Footer from "./component/Footer";

function App() {
    return (
        <div className={'container'}>
            <Hero/>
            <GodTeammates/>
            <Middle/>
            <Teammates/>
            <Footer/>
        </div>
    );
}

export default App;
