import Cross from '../assets/cross.svg'
import SpeechBabelWrapper from "./SpeechBabelWrapper";

const SpeechBabel = ({onClose, msgs, variant}) => {
    return <div onClick={() => onClose()} className={'speech-babel-background'}>
        <SpeechBabelWrapper variant={variant} onClose={() => onClose()}>
            <div className={'speech-babel-content'}>
                {msgs.map((msg, idx) => <div className={'speech-babel-speech'} key={msg.Nominee + idx}>
                    {idx===0&&<h3>To {msg.Nominee}</h3>}
                    <br/>
                    <p>{msg.Appreciate}</p>
                    <span>by {msg.Nominator}</span>
                </div>)}

            </div>
        </SpeechBabelWrapper>
    </div>
}

export default SpeechBabel;