import Avatar from "./Avatar";
import Avatar1 from '../assets/avatar1.png'
import Avatar2 from '../assets/avatar2.png'
import Avatar3 from '../assets/avatar3.png'
import Avatar4 from '../assets/avatar4.png'
import avatar5 from '../assets/avatar5.png'
import SpeechBabel from "./SpeechBabel";
import { useState } from "react";

const avatars = [
    { user: 'Ronald Poon', img: Avatar1 },
    { user: 'Samuel Wong', img: Avatar2 },
    { user: 'Bricky Lee', img: Avatar3 },
    { user: 'Ray Chan', img: Avatar4 },
    { user: 'Sam Yau', img: avatar5 }
];
const members = require('../assets/data.json');

const GodTeammates = () => {
    const [currentMember, setCurrentMember] = useState(undefined);
    const currentMemberMsgs = members.filter(member => member.Nominee === currentMember);

    return <section id="god" className={'god-teammates'}>
        <div className={'god-teammates-wrapper'}>
            <h2 className={'god-teammates-title'}>
                GOD teammates of the year
            </h2>
            <div className={'avatars'}>
                {avatars.map((avatar, idx) => <Avatar style={{ transform: `translateY(${-30 * idx}px)` }}
                    onSetMember={() => setCurrentMember(avatar.user)}
                    key={avatar.user}
                    avator={avatar} />)}
            </div>
            <span className={'god-teammates-disclaimer'}>*Names listed in no particular order</span>
        </div>
        {currentMember && <SpeechBabel msgs={currentMemberMsgs} onClose={() => setCurrentMember(null)} />}
    </section>
}

export default GodTeammates;